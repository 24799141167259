import { usePutRestoreHome } from "src/data/paradise/paradiseHomes/queries/paradiseHomeQueries"
import { MDialog } from "src/ui/Dialog/MDialog"
import { MText } from "src/ui/MText"

export function ParadiseHomeRestoreDialog({
  open,
  onClose,
  homeName,
  homeId,
}: {
  open: boolean
  onClose: () => void
  homeName: string
  homeId: string
}) {
  const putRestoreHome = usePutRestoreHome()

  function handleRestore() {
    putRestoreHome.mutate(homeId, {
      onSuccess: () => {
        onClose()
      },
    })
  }

  return (
    <MDialog
      open={open}
      title={`Restore ${homeName}`}
      onClose={() => {
        onClose()
      }}
      confirmLabel={"Restore"}
      loading={putRestoreHome.isLoading}
      onConfirm={handleRestore}
      error={
        putRestoreHome.isError &&
        (putRestoreHome.error?.response?.data?.message ??
          putRestoreHome.error.message)
      }
    >
      <div>
        <MText variant="body">
          Doing this will have the following effects:
          <ul>
            <li>The home will be restored to the account</li>
            <li>Previously installed devices will be restored</li>
            <li>Home token related to this home will be restored</li>
            <li>
              If this is the owners only home their subscription will be
              re-activated
            </li>
          </ul>
        </MText>
      </div>
    </MDialog>
  )
}
