import { Fragment, useState } from "react"
import styled from "styled-components"

import { ParadiseButtonsPager } from "src/components/Paradise/ParadiseButtonsPager"
import { ParadiseTable } from "src/components/Paradise/ParadiseTable"
import { useFetchParadiseHomeLog } from "src/data/paradise/paradiseHomes/queries/paradiseHomeQueries"
import { Routes } from "src/router/routes"
import { InternalLink } from "src/ui/Link/InternalLink"

const LIMIT = 15

export function ParadiseHomeLogs({ homeId }: { homeId: string }) {
  const [offset, setOffset] = useState(0)

  const fetchHomeLogs = useFetchParadiseHomeLog({
    homeId: homeId,
    filter: {
      offset,
      limit: LIMIT,
    },
    options: {
      keepPreviousData: true,
    },
  })
  const homeLogs = fetchHomeLogs.data?.entries

  /*
    Here we fetch the next set of logs to check if there are any more
    This data is used to render the "Next" button in pagination
    We have to do this due to backend only returning logs and not "total_count" for this endpoint

    This is also why we do not use <Pager /> component for pagination
  */
  const { data: nextData } = useFetchParadiseHomeLog({
    homeId: homeId,
    filter: {
      offset: offset + LIMIT,
      limit: LIMIT,
    },
  })

  const header = [
    <div key={"event"}>Event</div>,
    <div key={"time"}>Time</div>,
    <div key={"user-id"}>User id</div>,
  ]

  const rows = homeLogs?.map((log, i) => (
    <Fragment key={i}>
      <div>
        {log.home_event} {log.alarm_mode && `(${log.alarm_mode})`}
      </div>
      <div>{log.created_at}</div>
      {log.user_id ? (
        <InternalLink to={Routes.ParadiseUser.location(log.user_id)}>
          {log.user_id}
        </InternalLink>
      ) : (
        <div>-</div>
      )}
    </Fragment>
  ))

  return (
    <div>
      <TableWrapper>
        <ParadiseTable
          header={header}
          rows={rows ?? []}
          error={{
            hasError: fetchHomeLogs.isError,
            title: fetchHomeLogs.error?.message,
          }}
        />

        <ParadiseButtonsPager
          offset={offset}
          limit={LIMIT}
          setOffset={setOffset}
          hasNextPage={nextData && nextData.entries.length > 0}
        />
      </TableWrapper>
    </div>
  )
}

const TableWrapper = styled.div`
  white-space: nowrap;
  overflow: auto;
`
