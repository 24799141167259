import { ParadiseOverviewExpandable } from "src/components/Paradise/ParadiseOverviewExpandable"
import { ParadiseSettingStatic } from "src/components/Paradise/ParadiseSettings/ParadiseSettingStatic"
import { CopyWrapper } from "src/components/Paradise/sharedStyles"
import { IParadiseHome } from "src/data/paradise/paradiseHomes/types/paradiseHomeQuerytypes"
import { Routes } from "src/router/routes"
import { CopyText } from "src/ui/CopyText/CopyText"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MText } from "src/ui/MText"
import { formatUtcDate } from "src/utils/l10n"

import { ParadiseHomeOverviewTags } from "./ParadiseHomeOverviewTags"

export function ParadiseHomeOverview({ home }: { home: IParadiseHome }) {
  return (
    <ParadiseOverviewExpandable rawJson={home}>
      <ParadiseSettingStatic
        title="Home id"
        value={
          <CopyWrapper>
            <MText variant="bodyS">{home.id}</MText>
            <CopyText value={home.id} copiedText="Copied home id" width={18} />
          </CopyWrapper>
        }
      />
      <ParadiseSettingStatic title="Name" value={home.name} />

      {home.owner.type === "user" ? (
        <ParadiseSettingStatic
          title="Owner id"
          value={
            <CopyWrapper>
              <InternalLink to={Routes.ParadiseUser.location(home.owner.id)}>
                {home.owner.id}
              </InternalLink>
              <CopyText
                value={home.owner.id}
                copiedText="Copied owner id"
                width={18}
              />
            </CopyWrapper>
          }
        />
      ) : (
        <ParadiseSettingStatic
          title="Organization id"
          value={
            <CopyWrapper>
              <InternalLink
                to={Routes.ParadiseOrganization.location(home.owner.id)}
              >
                {home.owner.id}
              </InternalLink>
              <CopyText
                value={home.owner.id}
                copiedText="Copied owner id"
                width={18}
              />
            </CopyWrapper>
          }
        />
      )}

      <ParadiseHomeOverviewTags currentTags={home.tags} homeId={home.id} />

      <ParadiseSettingStatic
        title="Monitoring profile"
        value={
          <CopyWrapper>
            <MText variant="bodyS">{home.profile_id}</MText>
            <CopyText
              value={home.profile_id}
              copiedText="Copied profile id"
              width={18}
            />
          </CopyWrapper>
        }
      />

      <ParadiseSettingStatic
        title="Created at"
        value={formatUtcDate({ date: home.created_at })}
      />

      <ParadiseSettingStatic
        title="Created by"
        value={
          <CopyWrapper>
            <InternalLink to={Routes.ParadiseUser.location(home.created_by)}>
              {home.created_by}
            </InternalLink>
            <CopyText value={home.created_by} copiedText="Copied" width={18} />
          </CopyWrapper>
        }
      />

      <ParadiseSettingStatic
        title="Location"
        value={
          <ExternalLink
            href={`https://maps.google.com/maps?q=${home.location.latitude},${home.location.longitude}`}
          >
            View on Google maps
          </ExternalLink>
        }
      />

      <ParadiseSettingStatic title="Timezone" value={home.timezone} />

      <ParadiseSettingStatic
        title="Violations"
        value={
          home.violations.length > 0
            ? home.violations.map((violation) => violation)
            : "-"
        }
      />
    </ParadiseOverviewExpandable>
  )
}
