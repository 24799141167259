import { ParadiseDetailsSection } from "src/components/Paradise/ParadiseDetatilsLayout"
import { ParadiseDevicesFilters } from "src/components/Paradise/ParadiseDevicesFilter"
import { ParadiseDevicesTable } from "src/components/Paradise/ParadiseDevicesTable"
import { useParadiseDevicesFilter } from "src/components/Paradise/useParadiseDevicesFilter"
import { useFetchParadiseDashboardDevices } from "src/data/paradise/paradiseDevices/queries/paradiseDeviceQueries"

const LIMIT = 10

export function ParadiseHomeDevices({ homeId }: { homeId: string }) {
  const { filter, setFilter, offset, setOffset } = useParadiseDevicesFilter()

  const fetchedHomeDevices = useFetchParadiseDashboardDevices({
    filter: {
      home_id: homeId,
      limit: LIMIT,
      offset,
      device_id: filter.devices_id || undefined,
      description: filter.devices_description || undefined,
      // We only want to support `All` or `Active only` until we swap API
      active: !filter.devices_include_inactive || undefined,
      offline: filter.devices_offline ?? undefined,
      hardware_version: filter.devices_hardwareVersion ?? undefined,
    },
    options: {
      keepPreviousData: true,
    },
  })

  return (
    <div>
      <ParadiseDetailsSection title="Devices" />
      <ParadiseDevicesFilters filter={filter} setFilter={setFilter} />
      <ParadiseDevicesTable
        devices={fetchedHomeDevices.data?.devices ?? []}
        offset={offset}
        limit={LIMIT}
        setOffset={setOffset}
        totalCount={fetchedHomeDevices.data?.total_count}
        isLoading={fetchedHomeDevices.isPreviousData}
        error={{
          hasError: fetchedHomeDevices.isError,
          title: fetchedHomeDevices.error?.message,
        }}
      />
    </div>
  )
}
