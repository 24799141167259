import { useState } from "react"
import styled from "styled-components"

import { subMonths } from "date-fns"

import { EmptyState } from "src/components/EmptyState"
import { ParadiseLineChart } from "src/components/Paradise/ParadiseCharts/ParadiseLineChart"
import { useFetchOccupancyData } from "src/data/occupancy/queries/occupancyQueries"
import { DateRangePicker } from "src/ui/DateRangePicker/DateRangePicker"
import { MCircularProgress } from "src/ui/MCircularProgress/MCircularProgress"
import { spacing } from "src/ui/spacing"

export function ParadiseHomeOccupancyGraph({ homeId }: { homeId: string }) {
  const [selectedDate, setSelectedDate] = useState({
    startAt: subMonths(new Date(), 1),
    endAt: new Date(),
  })

  const fetchOccupancyData = useFetchOccupancyData({
    id: homeId,
    params: {
      time_resolution: 1,
      start_at: selectedDate.startAt.toISOString(),
      end_at: selectedDate.endAt.toISOString(),
      include_min_max: false,
      null_fill: false,
    },
  })

  if (fetchOccupancyData.isLoading) {
    return (
      <LoadingWrapper>
        <MCircularProgress />
      </LoadingWrapper>
    )
  }

  return (
    <div>
      <DateRangeBox>
        <DateRangePicker
          startDate={selectedDate.startAt}
          endDate={selectedDate.endAt}
          onDateRangeChange={({ startDate, endDate }) => {
            setSelectedDate({
              startAt: startDate,
              endAt: endDate,
            })
          }}
          dateRangeProps={{
            minBookingDays: 2,
          }}
          defaultPresetKey="DAY"
        />
      </DateRangeBox>

      {!fetchOccupancyData.data ||
      fetchOccupancyData.data.values.length === 0 ? (
        <EmptyState icon={<></>} title="No readings available" />
      ) : (
        <ParadiseLineChart
          data={fetchOccupancyData.data.values}
          tooltip={{
            unit: "",
          }}
          smooth={false}
          zoom="xy"
          canExport
        />
      )}
    </div>
  )
}

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const DateRangeBox = styled.div`
  margin-bottom: ${spacing.L};
`
